import { TimelineMax, TweenMax, Linear } from 'gsap';

  import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
  import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

  import ScrollMagic from 'scrollmagic';

const parallaxTarget = '#pageBannerTitle';

if($(parallaxTarget).length) {

        // initialise a scrollmagic controller
        var pageBannerController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onLeave", duration: "100%"}});

        // build scenes
        new ScrollMagic.Scene({triggerElement: "body"})
              .setTween("#pageBannerTitle", {y: "300px", ease: Linear.easeNone})
              //.addIndicators()
              .addTo(pageBannerController);

}
