import registerRowLinks from '../../src/js/partials/register-row-links.js';

var pagination = $('.js-pagination');

if (pagination.length) {

    pagination.each(function() {

        var prevButton = pagination.find('.js-pagination__item--prev a');
        var nextButton = pagination.find('.js-pagination__item--next a');
        var numberButtons = pagination.find('.js-pagination__item--number a');

        // counts
        var pageTotal = pagination.data('total');
        var pageCurrent = pagination.data('current');
        var pageAction = pagination.data('action');
        var pageTarget = pagination.data('target');
        var pageRecords = pagination.data('records');


        //console.log(pageTarget);

        numberButtons.each(function() {
            $(this).on('click', function(e) {
                e.preventDefault();
                numberButtons.removeClass('js-pagination__button--active');
                prevButton.removeClass('js-pagination__button--disabled');
                nextButton.removeClass('js-pagination__button--disabled');

                $(this).addClass('js-pagination__button--active');

                var currentSel = $(this).data('target');
                pageCurrent = currentSel;

                if (pageCurrent == 1) {
                    prevButton.addClass('js-pagination__button--disabled');
                }

                if (pageCurrent == pageTotal) {
                    nextButton.addClass('js-pagination__button--disabled');
                }

                updateRecords();
            });
        });

        prevButton.on('click', function(e) {
            e.preventDefault();

            if ($(this).hasClass('js-pagination__button--disabled')) {
                return false;
            }

            pageCurrent -= 1;
            numberButtons.removeClass('js-pagination__button--active');
            nextButton.removeClass('js-pagination__button--disabled');
            numberButtons.eq(pageCurrent - 1).addClass('js-pagination__button--active');

            if (pageCurrent == 1) {
                $(this).addClass('js-pagination__button--disabled');
            }

            updateRecords();
        });

        nextButton.on('click', function(e) {
            e.preventDefault();

            if ($(this).hasClass('js-pagination__button--disabled')) {
                return false;
            }

            pageCurrent += 1;
            numberButtons.removeClass('js-pagination__button--active');
            prevButton.removeClass('js-pagination__button--disabled');
            numberButtons.eq(pageCurrent - 1).addClass('js-pagination__button--active');

            if (pageCurrent == pageTotal) {
                $(this).addClass('js-pagination__button--disabled');
            }

            updateRecords();
        });


        function updateRecords() {

            $.ajax({
                type: 'POST',
                url: main_ajax.ajaxurl,
                data: {
                    action: pageAction,
                    current: pageCurrent,
                    records: pageRecords,
                    id: main_ajax.post_id
                },
                success: function(response) {
                    // replace current products
                    $(pageTarget).html(response);

                    registerRowLinks();
                }
            });
        }


    });


}