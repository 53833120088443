/*
* Menu handling
*/

// main menu toggle

$('.js-toggle-nav.header__toggle--nav').click(function() {
    $(this).toggleClass('is-active');
    $('.header__nav').toggleClass('is-active');
    $('body').toggleClass('mobile-menu--active');
});

// search toggle

$('.js-toggle-enquiry.header__toggle--enquiry').click(function() {
    $(this).toggleClass('is-active');
    $('.header__enquiry').toggleClass('is-active');
    $('body').toggleClass('mobile-enquiry--active');
});
