import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

const archiveTitleTarget = '.post-archive-latest__header-background';
var archiveTitleTrigger = '.post-archive-latest';

if($(archiveTitleTarget).length) {

    if(!archiveTitleController) {
        // initialise a scrollmagic controller
        var archiveTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(archiveTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: archiveTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(archiveTitleController);
    }
}
