export default function(registerRowLinks) {

    var rowLinks = $('.js-table-row-link');

    if (rowLinks.length) {

        rowLinks.each(function() {
            var target = $(this).data('target');

            $(this).on('click', function(e) {
                location.href = target;
            });
        });

    }

}