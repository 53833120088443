// floorplan-sides parallax

import { TimelineMax, TweenMax, Linear } from 'gsap';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var floorplanLeftTarget = '.floorplan-sides--left';
var floorplanRightTarget = '.floorplan-sides--right';

if($(floorplanLeftTarget).length && $(floorplanRightTarget).length) {

    // initialise a scrollmagic controller
    var floorplanSidesController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: '200%'}});

    // build scenes
    var sceneLeft = new ScrollMagic.Scene({triggerElement: floorplanLeftTarget})
          .setTween(floorplanLeftTarget, {y: "15%", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(floorplanSidesController);

    var sceneRight = new ScrollMagic.Scene({triggerElement: floorplanRightTarget})
          .setTween(floorplanRightTarget, {y: "15%", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(floorplanSidesController);

}
