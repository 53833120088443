import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var plotTableTitleTarget = '.development-plot-info-table__header-background';
var plotTableTitleTrigger = '.development-plot-info-table';

if($(plotTableTitleTarget).length) {

    if(!plotTableTitleController) {
        // initialise a scrollmagic controller
        var plotTableTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(plotTableTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: plotTableTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(plotTableTitleController);
    }
}