/* eslint prefer-arrow-callback:0 */
/* eslint global-require:0 */

/*
* JS external modules/libraries
*/

// import jquery. sort some namespaces out, as this will be largely handled by webpack.
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// import modernizr build
require('./js/vendor/modernizr.js');

// import responsive svg

// slick carousel
const slick = require('slick-carousel');

// scrollmagic & gsap
// import { TimelineMax, TweenMax, Linear } from 'gsap';

// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

// // const ScrollMagic = require('scrollmagic');
// import ScrollMagic from 'scrollmagic';

// it's possible to use es6 syntax.
// The babelify config will convert this to be compatible with the browsers
// targeted with browserslist
// i.e. let getMessage = () => 'Hello World';

$(document).ready(function () {
  $.fn.isInViewport = function () {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();

    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + ($(window).height() - 100);

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  function checkVisibility() {
    $('.js-anim').each(function () {
      if ($(this).isInViewport() === true) {
        $(this).addClass('js-anim--active');
      }
    });
  }

  $(window).scroll(function () {
    checkVisibility();
  });

  checkVisibility();

  require('./js/partials/responsive-svg.js');
  require('./js/partials/maps.js');
  require('./js/partials/register-row-links.js');
  require('../components/header/index.js');
  require('../components/banner-actions/index.js');
  require('../components/contact-map/index.js');
  require('../components/page-banner/index.js');
  require('../components/home-banner/index.js');
  require('../components/home-developments/index.js');
  require('../components/development-banner/index.js');
  require('../components/development-details/index.js');
  require('../components/development-floorplan/index.js');
  require('../components/post-archive-latest/index.js');
  require('../components/development-layout/index.js');
  require('../components/development-contact/index.js');
  require('../components/development-floorplan/index.js');
  require('../components/development-features/index.js');
  require('../components/development-types/index.js');
  require('../components/development-plots/index.js');
  require('../components/page-builder-steps/index.js');
  require('../components/page-builder-key-points/index.js');
  require('../components/floorplan/index.js');
  require('../components/floorplan-sides/index.js');
  require('../components/nav-primary/index.js');
  require('../components/developments-map/index.js');
  require('../components/page-image-banner/index.js');
  require('../components/js-pagination/index.js');
  require('../components/development-plot-info-table/index.js');
  require('../components/development-plot-info-table-row/index.js');
  require('../components/page-builder-key-cards/index.js');


  // console.log('mmmm, browsery. 📦');
});
