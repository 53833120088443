// developments slider

var development = $('.development-banner__slider');

if(development.length) {

    development.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.development-banner__count').text(i + '/' + slick.slideCount);
    });

    development.slick({
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        prevArrow: $('#development-banner-left'),
        nextArrow: $('#development-banner-right'),
        adaptiveHeight: false
    });
}

var vidWrapper = $('.development-banner');
var vidElement = document.getElementById('devVideo');

$('.development-banner__video-play').on('click', function(e) {
    e.preventDefault;

    vidWrapper.addClass('is-playing');
    vidElement.play();
});

$('.development-banner__video-close').on('click', function(e) {
    e.preventDefault;

    vidWrapper.removeClass('is-playing');
    vidElement.pause();
});
