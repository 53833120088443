// banner slider

var banner = $('.home-banner__slider');
var titleBanner = $('.home-banner__title-slider');

if(banner.length) {

    var bannerDelay;

    // function bannerTransition(dir) {
    //   $('.home-banner__slider-transition-bar').each(function(i){
    //     var cell = $(this);
    //     setTimeout(function() {
    //       cell.addClass('home-banner__slider-transition-bar--active');
    //     }, 200*i);
    //   });

    //   bannerDelay = setTimeout(bannerTimeout(dir), 800);
    // }

    // function bannerTimeout(dir) {
    //     $('.home-banner__slider-transition-bar').each(function(i){
    //       var cell = $(this);
    //       setTimeout(function() {
    //         cell.addClass('home-banner__slider-transition-bar--active');
    //       }, 200*i);
    //     });

    //     var moveNext = setTimeout(function() {
    //       banner.slick('slickNext');
    //     }, 800);
    //     clearTimeout(bannerDelay);
    //     bannerDelay = setTimeout(bannerTimeout, 16000);
    // }

    // // set timeout manually
    // banner.on('init', function(event, slick) {
    //   //bannerDelay = setTimeout(bannerTimeout, 16000);
    // });

    $('#home-banner-left').on('click', function(){
      // $('.home-banner__slider-transition-bar').each(function(i){
      //   var cell = $(this);
      //   setTimeout(function() {
      //     cell.addClass('home-banner__slider-transition-bar--active');
      //   }, 200*i);
      // });

      // var moveNext = setTimeout(function() {
      //     banner.slick('slickPrev');
      //   }, 800);

      // bannerTimeout();

      banner.slick('slickPrev');
      var current = banner.find('.slick-current').attr("data-slick-index");

      titleBanner.slick('slickGoTo',parseInt(current));
    });

    $('#home-banner-right').on('click', function(){
      // $('.home-banner__slider-transition-bar').each(function(i){
      //   var cell = $(this);
      //   setTimeout(function() {
      //     cell.addClass('home-banner__slider-transition-bar--active');
      //   }, 200*i);
      // });

      // var moveNext = setTimeout(function() {
      //     banner.slick('slickNext');
      //   }, 800);

      // bannerTimeout();

      banner.slick('slickNext');
      var current = banner.find('.slick-current').attr("data-slick-index");

      titleBanner.slick('slickGoTo',parseInt(current));
    });

    // banner.on('afterChange', function(event, slick, currentSlide, nextSlide){
    //   // $('.home-banner__slider-transition-bar').each(function(i){
    //   //   var cell = $(this);
    //   //   setTimeout(function() {
    //   //     cell.removeClass('home-banner__slider-transition-bar--active');
    //   //   }, 200*i);
    //   // });
    // });

    banner.slick({
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 16000,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    });

    titleBanner.slick({
        arrows: false,
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    });
}

// banner parallax

import { TimelineMax, TweenMax, Linear } from 'gsap';

  import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
  import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

  import ScrollMagic from 'scrollmagic';

const parallaxTarget = '#homeBanner';

if($(parallaxTarget).length) {

    // initialise a scrollmagic controller
    var pageBannerController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onLeave", duration: "100%"}});

    // build scenes
    var scene = new ScrollMagic.Scene({triggerElement: parallaxTarget})
          .setTween("#homeBannerSlider", {y: "300px", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(pageBannerController);

    var scene2 = new ScrollMagic.Scene({triggerElement: parallaxTarget})
          .setTween("#homeBannerDiscover", {y: "60px", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(pageBannerController);

}



