// floorplan parallax

import { TimelineMax, TweenMax, Linear } from 'gsap';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var floorplanTarget = '.floorplan';

if($(floorplanTarget).length) {

    // initialise a scrollmagic controller
    var floorplanController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: '200%'}});

    // build scenes
    var scene = new ScrollMagic.Scene({triggerElement: floorplanTarget})
          .setTween(".floorplan", {y: "55%", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(floorplanController);

}
