import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var stepsTitleTarget = '.page-builder-steps__header-background';
var stepsTitleTrigger = '.page-builder-steps';

if($(stepsTitleTarget).length) {

    if(!stepsTitleController) {
        // initialise a scrollmagic controller
        var stepsTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(stepsTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: stepsTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(stepsTitleController);
    }
}

var stepTarget = '.page-builder-steps__step-wrap';
var stepTrigger = '.page-builder-steps__wrap';

if($(stepTarget).length) {

    if(!stepController) {
        // initialise a scrollmagic controller
        var stepController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var stagger = stepTarget;
        var tl = new TimelineMax();
        tl.staggerFrom(stagger, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: stepTrigger, reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(stepController);
    }
}
