// title background animation

import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var detailsTitleTarget = '.development-details__header-background';
var detailsTitleTrigger = '.development-details__header';

if($(detailsTitleTarget).length) {

    if(!detailsTitleController) {
        // initialise a scrollmagic controller
        var detailsTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(detailsTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: detailsTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(detailsTitleController);
    }
}

// slider + navigation for development details

// development-details__navigation

var details_nav = $('.development-details__navigation-item');
var details_slider = $('.development-details__slider');

if(details_nav.length && details_slider.length) {

    details_nav.on('click', function(e) {
        e.preventDefault();
        var slideIndex = $(this).data('index');
        details_slider.slick('slickGoTo',parseInt(slideIndex));
        $('.development-details__navigation-item').removeClass('development-details__navigation-item--active');
        $(this).addClass('development-details__navigation-item--active');
    });

    details_slider.slick({
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        adaptiveHeight: true
    });
}
