// developments slider

var developments = $('.home-developments__slider');

if(developments.length) {

    developments.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.home-developments__count').text(i + '/' + slick.slideCount);
    });

    developments.slick({
        arrows: true,
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        prevArrow: $('#home-developments-left'),
        nextArrow: $('#home-developments-right'),
        adaptiveHeight: true
    });
}
