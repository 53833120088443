// floorplan slider

var floorplan = $('.development-floorplan__slider--active');

if(floorplan.length) {

    floorplan.slick({
        arrows: true,
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        prevArrow: $('#development-floorplan-left'),
        nextArrow: $('#development-floorplan-right'),
        adaptiveHeight: true
    });
}


// title background animation

import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var floorplanTitleTarget = '.development-floorplan__header-background';
var floorplanTitleTrigger = '.development-floorplan';

if($(floorplanTitleTarget).length) {

    if(!floorplanTitleController) {
        // initialise a scrollmagic controller
        var floorplanTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(floorplanTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: floorplanTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(floorplanTitleController);
    }
}
