// callback toggle

$('.js-toggle-callback.banner-actions__toggle-callback').click(function() {
    $(this).toggleClass('is-active');
    $('.banner-actions__action--callback').toggleClass('is-active');
    $('body').toggleClass('banner-callback--active');

    $('.js-toggle-brochure.banner-actions__toggle-brochure').removeClass('is-active');
    $('.banner-actions__action--brochure').removeClass('is-active');
    $('body').removeClass('banner-brochure--active');
});

// brochure toggle

$('.js-toggle-brochure.banner-actions__toggle-brochure').click(function() {
    $(this).toggleClass('is-active');
    $('.banner-actions__action--brochure').toggleClass('is-active');
    $('body').toggleClass('banner-brochure--active');

    $('.js-toggle-callback.banner-actions__toggle-callback').removeClass('is-active');
    $('.banner-actions__action--callback').removeClass('is-active');
    $('body').removeClass('banner-callback--active');
});
