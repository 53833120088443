import { TimelineMax, TweenMax, Linear, Power1 } from 'gsap';
import SplitText from '../../src/js/vendor/SplitText.js';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var contactTitleTarget = '.development-contact__header-background';
var contactTitleTrigger = '.development-contact';

if($(contactTitleTarget).length) {

    if(!contactTitleController) {
        // initialise a scrollmagic controller
        var contactTitleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter"}});

        var split = new SplitText(contactTitleTarget,{type: 'chars'});

        var tl = new TimelineMax();
        tl.staggerFrom(split.chars, 1.6, {y:100, opacity: 0, ease: Power1.easeOut}, 0.2);

        // build scenes
        new ScrollMagic.Scene({triggerElement: contactTitleTrigger,reverse: false})
              .setTween(tl)
              //.addIndicators()
              .addTo(contactTitleController);
    }
}
