// page slider

var page_banner = $('.page-image-banner__slider');

if(page_banner.length) {

    page_banner.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.page-image-banner__count').text(i + '/' + slick.slideCount);
    });

    page_banner.slick({
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        prevArrow: $('#page-image-banner-left'),
        nextArrow: $('#page-image-banner-right'),
        adaptiveHeight: false
    });
}

var vidWrapper = $('.page-image-banner');
var vidElement = document.getElementById('devVideo');

$('.page-image-banner__video-play').on('click', function(e) {
    e.preventDefault;

    vidWrapper.addClass('is-playing');
    vidElement.play();
});

$('.page-image-banner__video-close').on('click', function(e) {
    e.preventDefault;

    vidWrapper.removeClass('is-playing');
    vidElement.pause();
});
