import registerRowLinks from '../../src/js/partials/register-row-links.js';

var rowLinks = $('.js-table-row-link');

if (rowLinks.length) {

    rowLinks.each(function() {
        var target = $(this).data('target');

        $(this).on('click', function(e) {
            console.log('clicked');
            location.href = target;
        });
    });

}